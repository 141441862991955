import React from 'react';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import apiHelper from '../../../utils/apiHelpers';

const SelectIssueAssignDev = props => {
  const [options, setOptions] = useState(
    props.options.map(collaborator => {
      return { value: collaborator.login, label: collaborator.login };
    }),
  );

  const onChangeWrapper = value => {
    let event = { target: {} };
    event.target.name = props.name ? props.name : 'select-nameless';
    event.target.value = value
      ? value.map(col => {
          return { login: col.value };
        })
      : [];
    event.target.type = 'react-select';
    props.onChange(event);
  };

  return (
    <Select
      className={props.selectClassName}
      name={props.name}
      onChange={onChangeWrapper.bind(this)}
      value={props.value.map(collaborator => {
        return { value: collaborator.login, label: collaborator.login };
      })}
      required={props.required}
      options={options}
      clearable={false}
      placeholder={props.placeholder}
      menuContainerStyle={{ zIndex: 999 }}
      isDisabled={props.disabled}
      isMulti
    />
  );
};

export default SelectIssueAssignDev;
