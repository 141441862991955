import React from 'react';
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';
import apiHelpers from '../../../utils/apiHelpers';

const SelectBranch = ({
  value,
  name,
  projectName,
  required,
  placeholder,
  disabled,
  onChange,
  exclude = [],
}) => {
  const [options, setOptions] = useState([]);

  const onLoad = (input, callback) => {
    apiHelper.getProjectBranches(projectName, { input }).then(response => {
      let result = response.data.branches.map(branch => {
        return {
          value: branch.name,
          label: branch.name,
        };
      });
      result = result.filter(b => !exclude.includes(b.value));
      setOptions(result);
      callback(result);
    });
  };
  const onChangeWrapper = (selected, triggeredAction) => {
    let event = { target: {} };
    if (triggeredAction.action == 'clear') {
      event.target.name = name ? name : 'select-nameless';
      event.target.value = '';
      event.target.type = 'react-select';
    } else {
      event.target.name = name ? name : 'select-nameless';
      event.target.value = selected ? selected.value : '';
      event.target.type = 'react-select';
    }
    onChange(event);
  };

  return (
    <AsyncSelect
      onChange={onChangeWrapper}
      loadOptions={onLoad}
      value={options.find(o => o.value == value)}
      name={name}
      required={required}
      placeholder={placeholder ? placeholder : 'Nombre de la Rama'}
      isDisabled={disabled ? disabled : false}
      noOptionsMessage={() => 'No se encontraron ramas con el nombre ingresado'}
      styles={{
        option: provided => ({
          ...provided,
          color: 'black',
        }),
        control: provided => ({
          ...provided,
          color: 'black',
        }),
        singleValue: provided => ({
          ...provided,
          color: 'black',
        }),
      }}
    />
  );
};

export default SelectBranch;
