import React from 'react';
import { useEffect, useState } from 'react';
import './ProjectFilters.css';
import SelectProjectName from '../common/selects/SelectProjectName';

const ProjectFilters = props => {
  const [state, setState] = useState({
    state: null,
  });

  useEffect(() => {
    props.loadData(getFiltersObject());
  }, [state]);

  const getFiltersObject = () => {
    return {
      where: {
        id: state.id,
      },
    };
  };

  const onFiltersChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setState({ [name]: value });
  };

  const onFiltersRefresh = () => {
    setState({
      state: null,
    });
  };

  return (
    <div className="row transactions-filters">
      <div className="col-lg-4 col-sm-6">
        <SelectProjectName
          value={state.id}
          name="id"
          onChange={onFiltersChange.bind(this)}
        />
      </div>
      <div className="col-lg-8 col-sm-6">
        <div className="text-right">
          <button
            type="button"
            className="btn butt btn-warning "
            onClick={onFiltersRefresh.bind(this)}
            style={{ marginLeft: '2px' }}>
            Reiniciar filtros <i className="fas fa-sync"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectFilters;
