import React, { useState } from 'react';
const CommandBox = ({ command }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(command).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="command-box">
      <pre>{command}</pre>
      <button className="copy-button" onClick={handleCopy}>
        {copied ? 'Copied' : 'Copy'}
        <i class="far fa-clipboard ml-2"></i>
      </button>
    </div>
  );
};

export default CommandBox;
