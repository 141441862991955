import React from 'react';
import { useEffect, useState } from 'react';
import apiHelper from '../../utils/apiHelpers';
import { Link } from 'react-router-dom';
import './FirebaseRemoteConfig.css';
import StickyHeader from '../common/StickyHeader';
import FirebaseRemoteConfigFilters from './FirebaeRemoteConfigFilters';
import Loader from '../common/Loader';
import { useHistory } from 'react-router';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';

const FirebaseRemoteConfig = () => {
  const [loading, setLoading] = useState(true);
  const [firebaseRemoteConfigs, setFirebaseRemoteConfigs] = useState([]);
  const [filters, setFilters] = useState({});
  const history = useHistory();

  useEffect(() => {
    if (!loading) getFirebaseRemoteConfigs();
  }, []);

  useEffect(() => {
    getFirebaseRemoteConfigs();
  }, [filters]);

  const handleChangeFilters = filters => {
    setFilters(filters);
  };

  const getFirebaseRemoteConfigs = async () => {
    try {
      setLoading(true);
      let firebaseRemoteConfigs = await apiHelper.getFirebaseRemoteConfig();
      if (filters.where && filters.where.project)
        firebaseRemoteConfigs.data = firebaseRemoteConfigs.data.filter(
          firebaseRemoteConfig =>
            JSON.parse(
              firebaseRemoteConfig[1].defaultValue.value,
            ).project.toString() == filters.where.project,
        );
      setFirebaseRemoteConfigs(firebaseRemoteConfigs.data);
    } catch (e) {
      console.log('Error loading firebase remote configs');
      if (e.response && e.response.status == 401) history.push('/login');
    }
    setLoading(false);
  };

  return (
    <div className="d-flex flex-column h-100 general">
      <StickyHeader
        titulo={'Configuraciones remotas'}
        icon={'fas fa-cogs icon-vtasks'}
      />

      <div className="container">
        <Link to={`${process.env.PUBLIC_URL}/firebaseRemoteConfig/new`}>
          <button type="button" className="btn butt btn-success pull-right">
            Agregar <i className="fas fa-plus-square" />
          </button>
        </Link>
        <FirebaseRemoteConfigFilters loadData={handleChangeFilters} />

        <div className="table-responsive mt-3">
          {loading ? (
            <Loader />
          ) : (
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="align-middle" scope="col">
                    Proyecto
                  </th>
                  <th className="align-middle" scope="col">
                    Ambiente
                  </th>
                  <th className="align-middle" scope="col">
                    Descripción
                  </th>
                  <th className="align-middle" scope="col">
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {firebaseRemoteConfigs.sort().map(firebaseRemoteConfig => {
                  return (
                    <tr key={firebaseRemoteConfig.id}>
                      <td>
                        <span className="text-grey">
                          {firebaseRemoteConfig[0].split('_')[0]}
                        </span>
                      </td>
                      <td>
                        <span className="text-grey">
                          {firebaseRemoteConfig[0].split('_').length > 1
                            ? firebaseRemoteConfig[0].split('_')[1]
                            : ''}
                        </span>
                      </td>
                      <td>
                        <span className="text-grey">
                          {firebaseRemoteConfig[1].description}
                        </span>
                      </td>
                      <td>
                        <div className="text-center">
                          <Link
                            to={`${process.env.PUBLIC_URL}/firebaseRemoteConfig/${firebaseRemoteConfig[0]}`}>
                            <button
                              type="button"
                              className="btn buttEdit btn-circle">
                              <span className="fa fa-eye text-white" />
                            </button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default FirebaseRemoteConfig;
