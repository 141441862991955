import React from 'react';
import './IssueReport.css';
import formatter from '../../utils/formatter';
import moment from 'moment';

const IssueStats = props => {
  let totalProductionIssues = props.issues.filter(
    issue => issue.state == 'closed',
    // && issue.labels.some(label => label.name === 'Production'),
  ).length;
  let totalPendingIssues = props.issues.filter(
    issue => issue.state != 'closed',
    // && issue.labels.some(label => label.name === 'Production'),
  ).length;
  let totalClientIssues = props.issues.filter(
    issue => issue.column == 'Cliente',
  ).length;
  let totalOnTimeIssues = props.issues.filter(
    issue =>
      issue.state == 'closed' &&
      issue.labels.some(label => label.name === 'Production') &&
      issue.labels.some(label => label.name === 'Urgent') &&
      moment(issue.created_at).diff(moment(issue.closed_at), 'days') < 3,
    // && issue.labels.some(label => label.name === 'Production'),
  ).length;
  let totalOutTimeIssues = props.issues.filter(
    issue =>
      issue.state == 'closed' &&
      issue.labels.some(label => label.name === 'Production') &&
      !issue.labels.some(label => label.name === 'Urgent') &&
      moment(issue.created_at).diff(moment(issue.closed_at), 'days') > 3,
    // && issue.labels.some(label => label.name === 'Production'),
  ).length;

  return (
    <div className="row mb-3">
      <div className="col-3 text-center">
        <span className="text-white">
          Tareas pendientes: {totalPendingIssues}
        </span>
      </div>
      <div className="col-3 text-center">
        <span className="text-white">
          Tareas en cliente: {totalClientIssues}
        </span>
      </div>
      <div className="col-3 text-center">
        <span className="text-white">
          Tareas resueltas: {totalProductionIssues}
        </span>
      </div>
      <div className="col-3 text-center">
        <span className="text-white">
          Resuelto a tiempo: {totalOnTimeIssues}/
          {totalOnTimeIssues + totalOutTimeIssues}
        </span>
      </div>
    </div>
  );
};

export default IssueStats;
