import React from 'react';
import { useEffect, useState } from 'react';
import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import formatter from '../../utils/formatter';
import Tooltip from '../common/Tooltip';
import SelectUserRole from '../common/selects/SelectUserRole';
import StickyHeader from './../common/StickyHeader';
import UserProjects from './UserProjects';

const UserDetail = props => {
  const [user, setUser] = useState({
    username: '',
    email: '',
    password: '',
    projects: '',
    apikey: '',
    userRole: {
      id: 0,
      userRole: '',
    },
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isNew] = useState(props.match.params.id === 'new');

  useEffect(() => {
    if (!isNew) {
      apiHelper
        .getUser(props.match.params.id)
        .then(response => {
          setUser(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, []);

  const addProject = async project => {
    const newUser = Object.assign({}, user);
    let projects = user.projects.split(',');
    if (projects.indexOf(project.toString()) === -1)
      projects.push(project.toString());
    newUser['projects'] = projects.join(',');
    setUser(newUser);
  };

  const removeProject = async project => {
    const newUser = Object.assign({}, user);
    let projects = user.projects.split(',');
    if (projects.indexOf(project) !== -1)
      projects.splice(projects.indexOf(project), 1);
    newUser['projects'] = projects.join(',');
    setUser(newUser);
  };

  const handleChange = event => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    value =
      target.type === 'react-datetime'
        ? formatter.formatDateForAPI(target.value)
        : target.value;
    const name = target.name;
    const newUser = Object.assign({}, user);
    newUser[name] = value;
    setUser(newUser);
  };

  const deleteUser = async () => {
    await apiHelper.deleteUser(user.id);
    props.history.push('/user');
  };

  const sendMailUser = async () => {
    await apiHelper.sendNewUserEmail(user.id);
    setErrorMessage('Mail enviado');
  };

  const submitUser = async () => {
    try {
      setLoading(true);
      //If requests already exists, update it. If not, create it
      if (isNew) {
        await apiHelper.postUser(user);
      } else {
        await apiHelper.patchUser(user.id, user);
      }
      setLoading(false);
      props.history.push('/user');
    } catch (error) {
      setLoading(false);
      const statusCode = error.response.status;
      let errorMessage;
      if (statusCode === 422) {
        errorMessage = 'Datos invalidos o incompletos';
      } else {
        errorMessage = error.response.data.error
          ? error.response.data.error.message
          : error.response.data;
      }
      setErrorMessage(errorMessage);
    }
  };

  let alert;
  let title;
  let deleteButton;
  let sendMailButton;

  if (errorMessage) {
    alert = <div className="alert alert-warning">{errorMessage}</div>;
  }

  if (isNew) {
    title = 'Crear Usuario';
  } else {
    title = user.name;
    deleteButton = (
      <button
        className="btn btn-danger pull-right"
        type="button"
        onClick={e => deleteUser()}>
        <i className="fa fa-trash-alt" /> Eliminar
      </button>
    );
    sendMailButton = (
      <button
        className="btn btn-info pull-right mr-3"
        type="button"
        onClick={e => sendMailUser()}>
        <i className="fa fa-mail-forward" /> Enviar mail
      </button>
    );
  }

  return (
    <div className="d-flex flex-column h-100 general">
      <StickyHeader titulo={title} icon={'fas fa-user icon-vtasks'} />
      <div className="container">
        <div className="row mb-1 mt-3 justify-content-end">
          {sendMailButton}
          <button className="btn butt mr-3" onClick={e => submitUser()}>
            Guardar <i className="fas fa-save" />
          </button>
          {deleteButton}
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-md-12 col-lg-3">
            <div className="form-group">
              <label htmlFor="username">Usuario</label>
              <span
                data-tip
                data-for={'Tooltip-username'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-username'}
                tooltipText={
                  'Nombre de usuario. Junto con la contraseña, se utilizará para iniciar sesión.'
                }
              />
              <input
                type="text"
                name="username"
                className="form-control"
                placeholder="No definido"
                value={user.username}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-3">
            <div className="form-group">
              <label htmlFor="password">Contraseña</label>
              <span
                data-tip
                data-for={'Tooltip-password'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-password'}
                tooltipText={
                  'Contraseña del usuario. Junto con el nombre de usuario, se utilizará para iniciar sesión.'
                }
              />
              <input
                type="text"
                name="password"
                className="form-control"
                placeholder="No definido"
                value={user.password}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-3">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <span
                data-tip
                data-for={'Tooltip-email'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-email'}
                tooltipText={
                  'Email del usuario. Se utilizará para recibir las notificaciones del sistema.'
                }
              />
              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="No definido"
                value={user.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-3">
            <div className="form-group">
              <label htmlFor="userRole">Rol</label>
              <span
                data-tip
                data-for={'Tooltip-userRole'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-userRole'}
                tooltipText={'Rol del usuario.'}
              />
              <SelectUserRole
                value={user.userRole}
                name={'userRole'}
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <label htmlFor="email">Clave de API</label>
              <span
                data-tip
                data-for={'Tooltip-apikey'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-apikey'}
                tooltipText={
                  'Clave de API del usuario. Se utilizará para permitir generar issues desde servicios externos.'
                }
              />
              <input
                type="text"
                name="apikey"
                className="form-control"
                placeholder="No definido"
                value={user.apikey}
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </div>
        <label htmlFor="projects">Proyectos asociados</label>
        <UserProjects
          projects={user.projects}
          onAddProject={addProject}
          onRemoveProject={removeProject}
        />
        {alert}
      </div>
    </div>
  );
};

export default UserDetail;
