import React from 'react';
import { useEffect, useState } from 'react';
import apiHelper from '../../utils/apiHelpers';
import { Link } from 'react-router-dom';
import Footer from '../common/Footer';
import Pagination from '../common/Pagination';
import './User.css';
import UserFilters from './UserFilters';
import StickyHeader from '../common/StickyHeader';

const User = () => {
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({});
  const [count, setCount] = useState(0);
  const [pageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getUsers();
  }, [filters]);

  useEffect(() => {
    getUsers();
  }, [currentPage]);

  const handleChangeFilters = filters => {
    setFilters(filters);
  };

  const handleChangePage = pageOfItems => {
    setCurrentPage(pageOfItems);
  };

  const getNumberOfPages = () => {
    return Math.ceil(count / pageSize);
  };

  const getUsers = async () => {
    try {
      const filtersWithPaging = {
        ...filters,
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
      };

      const [users, usersCount] = await Promise.all([
        await apiHelper.getUsers(filtersWithPaging),
        await apiHelper.getUsersCount(filters),
      ]);
      setUsers(users.data);
      setCount(usersCount.data.count);
    } catch (e) {
      console.log('Error loading users');
    }
  };

  return (
    <div className="d-flex flex-column h-100 general">
      <StickyHeader titulo={'Usuarios'} icon={'fas fa-user icon-vtasks'} />

      <div className="container">
        <Link to={`${process.env.PUBLIC_URL}/user/new`}>
          <button type="button" className="btn butt btn-success pull-right">
            Agregar <i className="fas fa-plus-square" />
          </button>
        </Link>

        <UserFilters loadData={handleChangeFilters} />

        <div className="table-responsive mt-3">
          <table className="table  table-hover">
            <thead>
              <tr>
                <th scope="col">Usuario</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => {
                return (
                  <tr key={user.id}>
                    <td>{user.username}</td>
                    <td>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn buttEdit btn-light btn-circle">
                          <Link
                            to={`${process.env.PUBLIC_URL}/user/${user.id}`}>
                            <span className="fa fa-edit text-white" />
                          </Link>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/*<div className="text-center">
        <Pagination
          onChangePage={handleChangePage}
          currentPage={currentPage}
          totalPages={getNumberOfPages()}
        />
      </div>*/}
    </div>
  );
};

export default User;
