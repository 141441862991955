import React from 'react';
import Item from './Item';
import { Droppable } from 'react-beautiful-dnd';
import { styled } from '../../../stitches.config';
import Tooltip from '../../common/Tooltip';

const Column = props => {
  const StyledColumn = styled('div', {
    padding: '24px 0',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 8,
    h2: {
      margin: 0,
      padding: '0 16px',
      textAlign: 'center',
    },
  });

  const StyledList = styled('div', {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: 8,
  });

  const getColumnTooltip = columnName => {
    switch (columnName) {
      case 'Backlog':
        return (
          <Tooltip
            id={'Tooltip-Backlog'}
            tooltipText={
              'Tareas que deben realizarse en el mediano y largo plazo.'
            }
          />
        );
        break;
      case 'Pendientes':
        return (
          <Tooltip
            id={'Tooltip-Pendientes'}
            tooltipText={
              'Tareas que deben realizarse en el corto plazo (tareas del sprint, feedback de sprint anteriores, errores en producción, etc).'
            }
          />
        );
        break;
      case 'En Curso':
        return (
          <Tooltip
            id={'Tooltip-EnCurso'}
            tooltipText={
              'Tareas sobre las que se está trabajando actualmente. Se actualiza en tiempo real.'
            }
          />
        );
        break;
      case 'QA':
        return (
          <Tooltip
            id={'Tooltip-QA'}
            tooltipText={
              'Tareas que están en proceso de revisión interna por el equipo de QA de Venturing.'
            }
          />
        );
        break;
      case 'Cliente':
        return (
          <Tooltip
            id={'Tooltip-Cliente'}
            tooltipText={
              'Tareas que requieren información del cliente y/o que debe validar en el entorno de pruebas provisto.'
            }
          />
        );
        break;
      case 'Hechos':
        return (
          <Tooltip
            id={'Tooltip-Hechos'}
            tooltipText={
              'Tareas que han sido completadas y validadas tanto por Venturing como por el cliente.'
            }
          />
        );
        break;
    }
  };

  return (
    <Droppable droppableId={props.column.id.toString()}>
      {provided => (
        <StyledColumn>
          <h2
            className={'title ' + props.onboarding}
            data-tip
            data-for={'Tooltip-' + props.column.name.replace(/\s/g, '')}>
            {props.column.name} ({props.column.issues.length})
          </h2>
          {getColumnTooltip(props.column.name)}
          <StyledList {...provided.droppableProps} ref={provided.innerRef}>
            {props.column.issues.map((issue, index) => (
              <Item
                key={issue.id.toString()}
                project={props.project}
                column={props.column.name}
                issue={issue}
                index={index}
              />
            ))}
            {provided.placeholder}
          </StyledList>
        </StyledColumn>
      )}
    </Droppable>
  );
};

export default Column;
