import React from 'react';
import PropTypes from 'prop-types';
import './Pagination.css';

function Pagination(props) {
  if (!props.totalPages) return null;

  const isFirstPage = props.currentPage === 1;
  const isLastPage = props.totalPages === props.currentPage;

  function range(start, end) {
    return Array.apply(0, Array(end - start)).map(
      (element, index) => index + start,
    );
  }

  return (
    <nav>
      <ul className="pagination pagination-sm justify-content-center">
        <li className={'page-item' + (isFirstPage ? ' disabled' : '')}>
          <span className="page-link" onClick={() => props.onChangePage(1)}>
            Primera
          </span>
        </li>
        <li className={'page-item' + (isFirstPage ? ' disabled' : '')}>
          <span
            className="page-link"
            onClick={() => props.onChangePage(props.currentPage - 1)}>
            &laquo;
          </span>
        </li>
        {range(
          Math.max(0, props.currentPage - 3),
          Math.min(props.totalPages, props.currentPage + 2),
        ).map(index => {
          const page = index + 1;
          return (
            <li
              key={index}
              className={
                'page-item' + (props.currentPage === page ? ' active' : '')
              }>
              <span
                className="page-link"
                onClick={() => props.onChangePage(page)}
                href="">
                {page}
              </span>
            </li>
          );
        })}
        <li className={'page-item' + (isLastPage ? ' disabled' : '')}>
          <span
            className="page-link"
            onClick={() => props.onChangePage(props.currentPage + 1)}>
            &raquo;
          </span>
        </li>
        <li className={'page-item' + (isLastPage ? ' disabled' : '')}>
          <span
            className="page-link"
            onClick={() => props.onChangePage(props.totalPages)}>
            Última
          </span>
        </li>
      </ul>
    </nav>
  );
}

Pagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
};

export default Pagination;
