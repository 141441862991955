import React from 'react';
import { useState } from 'react';
import SelectProjectName from '../common/selects/SelectProjectName';

const UserProjects = props => {
  const [selectedProject, setSelectedProject] = useState('');

  const addProject = async () => {
    props.onAddProject(selectedProject);
  };

  const removeProject = async project => {
    props.onRemoveProject(project);
  };

  const onSelectedProjectChange = async e => {
    setSelectedProject(e.target.value);
  };

  return (
    <>
      {props.projects
        .split(',')
        .filter(project => project.length != 0)
        .map(project => (
          <div key={project} className="row justify-content-center mt-3">
            <div className="col-lg-9">
              <SelectProjectName
                value={project}
                name={'selectedProject'}
                disabled
              />
            </div>
            <div className="col-lg-3">
              <button
                className="btn butt btn-info mr-3 w-100"
                onClick={e => removeProject(project)}>
                Desasociar Proyecto <i className="fas fa-trash" />
              </button>
            </div>
          </div>
        ))}
      <div className="row justify-content-center mt-3">
        <div className="col-lg-9">
          <SelectProjectName
            value={selectedProject}
            name={'selectedProject'}
            onChange={onSelectedProjectChange}
          />
        </div>
        <div className="col-lg-3">
          <button
            className="btn butt btn-info mr-3 w-100"
            onClick={e => addProject()}>
            Asociar Proyecto <i className="fas fa-save" />
          </button>
        </div>
      </div>
      {alert}
    </>
  );
};

export default UserProjects;
