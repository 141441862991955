import React from 'react';
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';

const SelectUserRole = props => {
  const [options, setOptions] = useState([]);

  const [input, setInput] = useState('');

  const [value, setValue] = useState(null);

  useEffect(() => {
    let { value } = props;
    let userValue;
    if (value) {
      userValue = options.find(option => option.id === value.id);
      if (!userValue && value.id) loadDefaultOptions(value);
    }
  }, [props.value]);

  const handleChange = selectedValue => {
    setValue(selectedValue);
    let event = { target: {} };
    event.target.name = props.name ? props.name : 'select-options-nameless';
    event.target.value = selectedValue ? selectedValue.value : null;
    event.target.type = 'react-select';
    props.onChange(event);
  };

  const loadOptions = (input, callback) => {
    if (!input.length) return;

    let filters = {
      where: {
        userRole: input,
      },
    };

    apiHelper
      .getUserRoles(filters)
      .then(response => {
        let entitiesForSelect = response.data.map(entity => {
          return {
            value: entity,
            label: entity.userRole,
          };
        });

        setOptions(entitiesForSelect);
        callback(entitiesForSelect);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const loadDefaultOptions = value => {
    if (!value.id) return;

    let filters = {
      where: {
        id: value.id,
      },
    };

    apiHelper
      .getUserRoles(filters)
      .then(response => {
        let entitiesForSelect = response.data
          .sort((a, b) => {
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
          })
          .map(entity => {
            return {
              value: entity,
              label: entity.userRole,
            };
          });

        setOptions(entitiesForSelect);
        setValue({ value: value, label: value.userRole });
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <AsyncSelect
      onChange={handleChange}
      loadOptions={loadOptions}
      value={value}
      name={props.name}
      required={props.required}
      placeholder="Rol"
      noOptionsMessage={() => 'No se encontraron roles con el nombre ingresado'}
    />
  );
};

export default SelectUserRole;
