import React, { useState } from 'react';
import Login from '../login/Login';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.css';
import PrivateRoute from '../../utils/PrivateRoute';
import UserDetail from '../user/UserDetail';
import User from '../user/User';
import NewAside from '../common/NewAside';
import Footer from '../common/Footer';
import IssueDetail from '../issues/IssueDetail';
import IssuesPending from '../issues/IssuesPending/IssuesPending';
import IssuesDelayed from '../issues/IssuesDelayed/IssuesDelayed';
import Project from '../projects/Project';
import IssueColumnsDraggable from '../issues/DragAndDrop/IssueColumnsDraggable';
import IssueReport from '../report/IssueReport';
import RecoverPassword from '../login/RecoverPassword';
import GeneratePassword from '../login/GeneratePassword';
import { VTasksWidget } from 'vtasks-widget';
import Milestone from '../milestones/Milestone';
import FirebaseRemoteConfig from '../firebaseRemoteConfig/FirebaseRemoteConfig';
import FirebaseRemoteConfigDetail from '../firebaseRemoteConfig/FirebaseRemoteConfigDetail';
import { AlertProvider } from '../../context/AlertContext';
//import VTasksWidget from "../widget/widget/VTasksWidget";

const history = createBrowserHistory();

function App() {
  return (
    <>
      <AlertProvider>
        <Router history={history}>
          <Switch>
            <Route name="login" path={`/login`} component={Login} />
            <Route
              name="recover_password"
              path={`/recover_password`}
              component={RecoverPassword}
            />
            <Route
              name="generate_password"
              path={`/generate_password`}
              component={GeneratePassword}
            />
            <div>
              <NewAside />
              <PrivateRoute
                name="issue"
                exact
                path={`/project/:project`}
                component={IssueColumnsDraggable}
              />
              <PrivateRoute
                name="project"
                exact
                path={`/project`}
                component={Project}
              />
              <PrivateRoute
                name="milestones"
                exact
                path={`/milestones`}
                component={Milestone}
              />
              <PrivateRoute
                name="firebaseRemoteConfig"
                exact
                path={`/firebaseRemoteConfig`}
                component={FirebaseRemoteConfig}
              />
              <PrivateRoute
                name="firebaseRemoteConfigDetail"
                exact
                path={`/firebaseRemoteConfig/:name`}
                component={FirebaseRemoteConfigDetail}
              />
              <PrivateRoute
                name="issueDetail"
                exact
                path={`/project/:project/issue/:id`}
                component={IssueDetail}
              />
              <PrivateRoute
                name="issueReport"
                exact
                path={`/report/:project`}
                component={IssueReport}
              />
              <PrivateRoute
                name="issuesPending"
                exact
                path={`/issues-pending`}
                component={IssuesPending}
              />
              <PrivateRoute
                name="issuesDelayed"
                exact
                path={`/issues-delayed`}
                component={IssuesDelayed}
              />
              <PrivateRoute
                name="userDetail"
                exact
                path={`/user/:id`}
                component={UserDetail}
              />
              <PrivateRoute name="user" exact path={`/user`} component={User} />
              <PrivateRoute
                name="default"
                exact
                path={`/`}
                component={Project}
              />
              <Footer />
            </div>
          </Switch>
        </Router>
      </AlertProvider>
    </>
  );
}

export default App;
