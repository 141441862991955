import React from 'react';
import './Loader.css';

const Loader = () => {
  return (
    <>
      <div className="background">
        <div>
          <img
            className="logoload logoloader"
            src={process.env.PUBLIC_URL + '/img/loader.png'}
            alt="logo"
          />
        </div>
      </div>
    </>
  );
};

export default Loader;
