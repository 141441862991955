import React from 'react';
import apiHelper from '../../utils/apiHelpers';
import formatter from '../../utils/formatter';
import { Link } from 'react-router-dom';
import './GeneratePassword.css';

class GeneratePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      username: '',
      password: '',
      password2: '',
      errorMessage: null,
    };
  }

  handleChange(event) {
    const target = event.target;
    const value = formatter.formatFromTarget(target);
    const name = target.name;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (this.state.password != this.state.password2) {
      this.setState({
        errorMessage: 'Las contraseñas ingresadas no coinciden',
      });
      return;
    }

    try {
      await apiHelper.generatePassword({
        email: this.state.email,
        username: this.state.username,
        password: this.state.password,
      });
      this.props.history.push('/login');
    } catch (error) {
      const statusCode = error.response.status;
      let errorMessage;

      if (statusCode === 401) {
        errorMessage = 'Email o usuario invalidos';
      } else {
        errorMessage = error.message;
      }

      this.setState({
        errorMessage: errorMessage,
      });
    }
  }

  render() {
    let alert;

    if (this.state.errorMessage) {
      alert = (
        <div className="alert alert-warning">{this.state.errorMessage}</div>
      );
    }

    return (
      <div className="container">
        <div className="wrapper fadeInDown">
          <form className="form-signin" onSubmit={this.handleSubmit.bind(this)}>
            <div className="fadeIn first">
              <h2 className="text-center mt-2">Generar nueva contraseña</h2>
              <p>
                Ingrese su usuario, direccion de email y una contraseña de su
                preferencia para completar el proceso de alta.
              </p>
              <div className="panel-body">
                <div className="form-group">
                  <div className="input-group mb-3">
                    <input
                      placeholder="Dirección de Email"
                      className="form-control"
                      name="email"
                      type="email"
                      value={this.state.email}
                      onChange={this.handleChange.bind(this)}
                      required
                      autoFocus
                    />
                  </div>
                  <div className="input-group mb-3">
                    <input
                      placeholder="Usuario"
                      className="form-control"
                      name="username"
                      type="username"
                      value={this.state.username}
                      onChange={this.handleChange.bind(this)}
                      required
                    />
                  </div>
                  <div className="input-group mb-3">
                    <input
                      placeholder="Contraseña"
                      className="form-control"
                      name="password"
                      type="password"
                      value={this.state.password}
                      onChange={this.handleChange.bind(this)}
                      required
                    />
                  </div>
                  <div className="input-group mb-3">
                    <input
                      placeholder="Reingrese Contraseña"
                      className="form-control"
                      name="password2"
                      type="password"
                      value={this.state.password2}
                      onChange={this.handleChange.bind(this)}
                      required
                    />
                  </div>
                </div>

                {alert}

                <button className="btn btn-primary btn-block" type="submit">
                  Generar contraseña
                </button>
              </div>

              <hr />

              <Link to="/login">Volver al Login</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default GeneratePassword;
