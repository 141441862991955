import React from 'react';

export default function ProgressLoader(props) {
  return (
    <div className="alert alert-info m-4" role="alert">
      <div className="progress">
        <div
          className="progress-bar bg-primary"
          style={{
            width: `${(props.currentValue / props.fullProcess) * 100}%`,
          }}></div>
      </div>
    </div>
  );
}
