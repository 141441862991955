import React from 'react';
import { useEffect, useState } from 'react';
import apiHelper from '../../utils/apiHelpers';
import { Link } from 'react-router-dom';
import Pagination from '../common/Pagination';
import './Milestone.css';
import StickyHeader from './../common/StickyHeader';
import MilestoneFilters from './MilestoneFilters';
import Loader from '../common/Loader';
import formatter from '../../utils/formatter';

const Milestone = () => {
  const [loading, setLoading] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const [filters, setFilters] = useState({});
  const [count, setCount] = useState(0);
  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!loading) getMilestones();
  }, []);

  useEffect(() => {
    getMilestones();
  }, [filters]);

  useEffect(() => {
    getMilestones();
  }, [currentPage]);

  const handleChangeFilters = filters => {
    setFilters(filters);
  };

  const handleChangePage = pageOfItems => {
    setCurrentPage(pageOfItems);
  };

  const getNumberOfPages = () => {
    return Math.ceil(count / pageSize);
  };

  const getMilestones = async () => {
    try {
      setLoading(true);
      const filtersWithPaging = {
        ...filters,
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
      };

      const [milestones, milestonesCount] = await Promise.all([
        await apiHelper.getMilestones(filtersWithPaging),
        await apiHelper.getMilestonesCount(filters),
      ]);
      if (filters.where && filters.where.project)
        milestones.data = milestones.data.filter(
          milestone =>
            milestone.repository.id.toString() == filters.where.project,
        );
      if (filters.where && filters.where.state)
        milestones.data = milestones.data.filter(
          milestone => milestone.state.toString() == filters.where.state,
        );
      setMilestones(milestones.data);
      setCount(milestonesCount.data.count);
    } catch (e) {
      console.log('Error loading milestones');
    }
    setLoading(false);
  };

  return (
    <div className="d-flex flex-column h-100 general">
      <StickyHeader
        titulo={'Hitos'}
        icon={'fas fa-calendar-check icon-vtasks'}
      />

      <div className="container">
        <MilestoneFilters loadData={handleChangeFilters} />

        <div className="table-responsive mt-3">
          {loading ? (
            <Loader />
          ) : (
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="align-middle" scope="col">
                    Proyecto
                  </th>
                  <th className="align-middle" scope="col">
                    Hito
                  </th>
                  <th className="align-middle" scope="col">
                    Fecha de finalización
                  </th>
                  <th className="align-middle" scope="col">
                    Progreso
                  </th>
                  <th className="align-middle" scope="col">
                    Estado
                  </th>
                  <th className="align-middle" scope="col">
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {milestones.map(milestone => {
                  return (
                    <tr key={milestone.id}>
                      <td>
                        <span className="text-grey">
                          {milestone.repository.name}
                        </span>
                      </td>
                      <td>
                        <span className="text-grey">{milestone.title}</span>
                      </td>
                      <td>
                        <span className="text-grey">
                          {formatter.formatDate(milestone.due_on)}
                        </span>
                      </td>
                      <td>
                        <span className="text-grey">
                          {milestone.closed_issues} /{' '}
                          {milestone.open_issues + milestone.closed_issues}
                        </span>
                      </td>
                      <td>
                        <span className="text-grey">
                          {milestone.state == 'open' ? 'Abierto' : 'Cerrado'}
                        </span>
                      </td>
                      <td>
                        <div className="text-center">
                          <Link
                            to={`${process.env.PUBLIC_URL}/project/${milestone.repository.name}`}>
                            <button
                              type="button"
                              className="btn buttEdit btn-circle">
                              <span className="fa fa-eye text-white" />
                            </button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {/*<div className="text-center">
        <Pagination
          onChangePage={handleChangePage}
          currentPage={currentPage}
          totalPages={getNumberOfPages()}
        />
      </div>*/}
    </div>
  );
};

export default Milestone;
