import React from 'react';
import { useEffect, useState } from 'react';
import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import formatter from '../../utils/formatter';
import Tooltip from '../common/Tooltip';
import StickyHeader from '../common/StickyHeader';
const cronParser = require('cron-parser');

const FirebaseRemoteConfigDetail = props => {
  const [description, setDescription] = useState('');
  const [firebaseRemoteConfig, setFirebaseRemoteConfig] = useState({});
  const [nextCronExecution, setNextCronExecution] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isNew] = useState(props.match.params.name === 'new');

  useEffect(() => {
    if (!isNew) {
      apiHelper
        .getOneFirebaseRemoteConfig(props.match.params.name)
        .then(response => {
          setDescription(response.data.description);
          setFirebaseRemoteConfig(response.data.firebaseRemoteConfig);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    // Update next cron execution
    try {
      setNextCronExecution(
        cronParser
          .parseExpression(firebaseRemoteConfig.scheduled_deploy)
          .next()
          .toString(),
      );
    } catch (e) {
      if (firebaseRemoteConfig.scheduled_deploy?.length) {
        setNextCronExecution('Configuración inválida');
      } else {
        setNextCronExecution(null);
      }
    }
  }, [firebaseRemoteConfig]);

  const handleChange = event => {
    const target = event.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    value =
      target.type === 'react-datetime'
        ? formatter.formatDateForAPI(target.value)
        : target.value;
    // If the fields is the "commands" field, join all lines as an array
    if (name == 'commands' || name == 'hosts') {
      // Split the content into an array
      value = value.split('\n');
    }
    const newConfig = Object.assign({}, firebaseRemoteConfig);
    newConfig[name] = value;
    setFirebaseRemoteConfig(newConfig);
  };

  const deleteConfig = async () => {
    await apiHelper.deleteFirebaseRemoteConfig(props.match.params.name);
    props.history.push('/firebaseRemoteConfig');
  };

  const submitConfig = async () => {
    try {
      setLoading(true);
      //For the commands fields, discard empty lines and give it proper formatting
      // If the fields is the "commands" or "hosts" field, discard empty elements
      if (firebaseRemoteConfig.commands)
        firebaseRemoteConfig.commands = firebaseRemoteConfig.commands.filter(
          elem => elem.length,
        );
      if (firebaseRemoteConfig.hosts)
        firebaseRemoteConfig.hosts = firebaseRemoteConfig.hosts.filter(
          elem => elem.length,
        );
      //If requests already exists, update it. If not, create it
      if (isNew) {
        await apiHelper.createFirebaseRemoteConfig({
          description,
          firebaseRemoteConfig,
        });
      } else {
        await apiHelper.updateFirebaseRemoteConfig(props.match.params.name, {
          description,
          firebaseRemoteConfig,
        });
      }
      setLoading(false);
      props.history.push('/firebaseRemoteConfig');
    } catch (error) {
      setLoading(false);
      const statusCode = error.response.status;
      let errorMessage;
      if (statusCode === 422) {
        errorMessage = 'Datos invalidos o incompletos';
      } else {
        errorMessage = error.response.data.error
          ? error.response.data.error.message
          : error.response.data;
      }
      setErrorMessage(errorMessage);
    }
  };

  const deployNow = e => {
    const crontabExpression = generateCrontabExpression();
    const newConfig = Object.assign({}, firebaseRemoteConfig);
    newConfig['scheduled_deploy'] = crontabExpression;
    setFirebaseRemoteConfig(newConfig);
  };

  function generateCrontabExpression() {
    const currentDate = new Date();
    const futureDate = new Date(currentDate.getTime() + 5 * 60000); // Add 5 minutes in milliseconds

    const minutes = futureDate.getMinutes();
    const hours = futureDate.getHours();
    const dayOfMonth = futureDate.getDate();
    const month = futureDate.getMonth() + 1; // Month is zero-based in JavaScript Date object

    return `${minutes} ${hours} ${dayOfMonth} ${month} *`;
  }

  let alert;
  let title;
  let deleteButton;

  if (errorMessage) {
    alert = <div className="alert alert-warning">{errorMessage}</div>;
  }

  if (isNew) {
    title = 'Crear Configuración Remota';
  } else {
    title = firebaseRemoteConfig.project;
    deleteButton = (
      <button
        className="btn btn-danger pull-right"
        type="button"
        onClick={e => deleteConfig()}>
        <i className="fa fa-trash-alt" /> Eliminar
      </button>
    );
  }

  return (
    <div className="d-flex flex-column h-100 general">
      <StickyHeader titulo={title} icon={'fas fa-user icon-vtasks'} />
      <div className="container">
        <div className="row mb-1 mt-3 justify-content-end">
          <button className="btn butt mr-3" onClick={e => submitConfig()}>
            Guardar <i className="fas fa-save" />
          </button>
          {deleteButton}
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <label htmlFor="project">Descripción</label>
              <span
                data-tip
                data-for={'Tooltip-description'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-description'}
                tooltipText={
                  'Indica la descripción de la configuración, por ejemplo "vTasks deploy configuration for production".'
                }
              />
              <input
                type="text"
                name="description"
                className="form-control"
                placeholder="No definido"
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="form-group">
              <label htmlFor="project">Proyecto</label>
              <span
                data-tip
                data-for={'Tooltip-project'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-project'}
                tooltipText={
                  'Indica el nombre del proyecto que se utiliza para ser detallado en los mensajes de Slack, y para detectar las configuraciones remotas asociadas.'
                }
              />
              <input
                type="text"
                name="project"
                className="form-control"
                placeholder="No definido"
                value={firebaseRemoteConfig.project}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="form-group">
              <label htmlFor="environment">Ambiente</label>
              <span
                data-tip
                data-for={'Tooltip-environment'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-environment'}
                tooltipText={
                  'Indica el entorno (desarrollo, producción, etc) sobre el cual se realiza la tarea, para ser detallado en los mensajes de Slack, y para detectar las configuraciones remotas asociadas.'
                }
              />
              <input
                type="text"
                name="environment"
                className="form-control"
                placeholder="No definido"
                value={firebaseRemoteConfig.environment}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="form-group">
              <label htmlFor="channel">Canal de Slack</label>
              <span
                data-tip
                data-for={'Tooltip-channel'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-channel'}
                tooltipText={
                  'Indica el canal de Slack en el cual se debe enviar el mensaje con el resultado de la ejecución.'
                }
              />
              <input
                type="text"
                name="channel"
                className="form-control"
                placeholder="No definido"
                value={firebaseRemoteConfig.channel}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="form-group">
              <label htmlFor="secret">Secreto</label>
              <span
                data-tip
                data-for={'Tooltip-secret'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-secret'}
                tooltipText={
                  'Indica el token secreto para autenticarse con el webhook de vDevOps para deploys por eventos. Para más información ver "Deploys automáticos por eventos" en vDevOps                  '
                }
              />
              <input
                type="text"
                name="secret"
                className="form-control"
                placeholder="No definido"
                value={firebaseRemoteConfig.secret}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="form-group">
              <label htmlFor="branch">Rama</label>
              <span
                data-tip
                data-for={'Tooltip-branch'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-branch'}
                tooltipText={
                  'Indica la rama del repositorio que debe usarse en deploys programados, y que debe utlizarse para detectar un nuevo commit el deploy por eventos.'
                }
              />
              <input
                type="text"
                name="branch"
                className="form-control"
                placeholder="No definido"
                value={firebaseRemoteConfig.branch}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="form-group">
              <label htmlFor="scheduled_deploy">Deploy programado</label>
              <span
                data-tip
                data-for={'Tooltip-scheduled_deploy'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-scheduled_deploy'}
                tooltipText={
                  'Indica la configuración del cron para ejecutar el proceso de deploy automático'
                }
              />
              {firebaseRemoteConfig.scheduled_deploy && (
                <label className="text-white ml-3">
                  <a
                    href={
                      'https://crontab.guru/#' +
                      firebaseRemoteConfig.scheduled_deploy.replace(' ', '_')
                    }
                    target="_blank">
                    Ver en Crontab Guru
                  </a>
                </label>
              )}
              <label
                className="text-white ml-3 p-1 butt rounded pull-right"
                onClick={deployNow}>
                Deployar en 5' <span className="fa fa-clock fa-sm"></span>
              </label>
              <input
                type="text"
                name="scheduled_deploy"
                className="form-control"
                placeholder="No definido"
                value={firebaseRemoteConfig.scheduled_deploy}
                onChange={handleChange}
              />
              {nextCronExecution && (
                <label className="text-white">
                  Próxima ejecución: {nextCronExecution}
                </label>
              )}
            </div>
          </div>
          <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <label htmlFor="rootPath">Ruta raiz del proyecto</label>
              <span
                data-tip
                data-for={'Tooltip-rootPath'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-rootPath'}
                tooltipText={
                  'Indica la ruta absoluta del proyecto dentro del entorno donde se encuentra instalado.'
                }
              />
              <input
                type="text"
                name="rootPath"
                className="form-control"
                placeholder="No definido"
                value={firebaseRemoteConfig.rootPath}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <label htmlFor="channel">Comandos</label>
              <span
                data-tip
                data-for={'Tooltip-commands'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-commands'}
                tooltipText={
                  'Indica la secuencia de comandos a ejecutar en el deploy. Debe detallarse uno por línea.'
                }
              />
              <textarea
                type="textarea"
                name="commands"
                className="form-control"
                placeholder="No definido"
                rows={
                  firebaseRemoteConfig.commands &&
                  firebaseRemoteConfig.commands.length
                    ? firebaseRemoteConfig.commands &&
                      firebaseRemoteConfig.commands.length
                    : 20
                }
                value={
                  firebaseRemoteConfig.commands &&
                  firebaseRemoteConfig.commands.length
                    ? firebaseRemoteConfig.commands.join('\n')
                    : ''
                }
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-12">
            <div className="form-group">
              <label htmlFor="channel">Servidores</label>
              <span
                data-tip
                data-for={'Tooltip-hosts'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-hosts'}
                tooltipText={
                  'Indica las IPs (y si estan disponibles, los hostnames) de los servidores que hacen o hicieron uso de esta configuración al menos una vez.'
                }
              />
              <textarea
                type="textarea"
                name="hosts"
                className="form-control"
                placeholder="No definido"
                rows={
                  firebaseRemoteConfig.hosts &&
                  firebaseRemoteConfig.hosts.length
                    ? firebaseRemoteConfig.hosts.length
                    : 0
                }
                value={
                  firebaseRemoteConfig.hosts &&
                  firebaseRemoteConfig.hosts.length
                    ? firebaseRemoteConfig.hosts.join('\n')
                    : ''
                }
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        {alert}
      </div>
    </div>
  );
};

export default FirebaseRemoteConfigDetail;
