import React from 'react';
import { useEffect, useState } from 'react';
import apiHelper from '../../utils/apiHelpers';
import { Link } from 'react-router-dom';
import Pagination from '../common/Pagination';
import './Project.css';
import StickyHeader from './../common/StickyHeader';
import ProjectFilters from './ProjectFilters';
import Loader from '../common/Loader';
import { useHistory } from 'react-router';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { useAlert } from '../../context/AlertContext';

const Project = () => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [filters, setFilters] = useState({});
  const [count, setCount] = useState(0);
  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const alert = useAlert();

  const [onboarding, setOnboarding] = useState({
    stepsEnabled: apiHelper.getOnboarding('onboardingProjects'),
    initialStep: 0,
    steps: [
      {
        element: '.firstStep',
        intro:
          '<h4>¡Bienvenido al portal de clientes de Venturing!</h4><br><p >Vamos a guiarle en sus primeros pasos</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.secondStep',
        intro:
          '<p >Aquí podrá encontrar una lista de los proyectos a los cuales se encuentra vinculado. Si no hallase algún proyecto en particular, puede solicitar el acceso al equipo de Venturing.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.thirdStep',
        intro:
          '<p >Si trabaja en múltiples proyectos, puede filtrar aquellos de su interés por su nombre desde la sección de filtros.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.fourthStep',
        intro:
          '<p >Para ver el detalle de un proyecto en particular, puede hacer click en el ícono correspondiente en la columna <b>Acciones</b>.</p>',
        tooltipClass: 'onboarding-steps',
      },
    ],
  });

  useEffect(() => {
    getProjects();
  }, [filters, currentPage]);

  // useEffect(() => {
  //   getProjects();
  // }, [filters]);

  // useEffect(() => {
  //   getProjects();
  // }, [currentPage]);

  const handleChangeFilters = filters => {
    setFilters(filters);
  };

  const handleChangePage = pageOfItems => {
    setCurrentPage(pageOfItems);
  };

  const getNumberOfPages = () => {
    return Math.ceil(count / pageSize);
  };

  const getProjects = async () => {
    try {
      setLoading(true);
      const filtersWithPaging = {
        ...filters,
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
      };

      const [issues, issuesCount] = await Promise.all([
        await apiHelper.getProjects(filtersWithPaging),
        await apiHelper.getProjectsCount(filters),
      ]);
      if (filters.where && filters.where.id)
        issues.data = issues.data.filter(
          project => project.id.toString() == filters.where.id,
        );
      let {
        data: { projects: userAssignedProjectsInDB },
      } = await apiHelper.getCurrentUser();
      issues.data = issues.data.filter(repo =>
        userAssignedProjectsInDB.includes(String(repo.id)),
      );
      setProjects(issues.data);
      setCount(issuesCount.data.count);
    } catch (e) {
      console.log('Error loading projects');
      if (e.response && e.response.status == 401) history.push('/login');
      alert.error('Error cargando proyectos');
    }
    setLoading(false);
  };

  const onExit = async () => {
    await apiHelper.updateOnboarding({
      onboarding: 'onboardingProjects',
      state: false,
    });
    setOnboarding(prevState => {
      return { ...prevState, stepsEnabled: false };
    });
  };

  return (
    <div className="d-flex flex-column h-100 general">
      <Steps
        enabled={onboarding.stepsEnabled && !loading}
        steps={onboarding.steps}
        initialStep={onboarding.initialStep}
        options={{
          nextLabel: 'Siguiente',
          prevLabel: 'Anterior',
          skipLabel: 'Saltar',
          doneLabel: 'Finalizar',
        }}
        onExit={onExit.bind(this)}
      />
      <StickyHeader
        titulo={'Proyectos'}
        icon={'fas fa-clipboard icon-vtasks'}
      />

      <div className="container">
        <ProjectFilters loadData={handleChangeFilters} />

        <div className="table-responsive mt-3">
          {loading ? (
            <Loader />
          ) : (
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="align-middle" scope="col">
                    Nombre
                  </th>
                  <th className="align-middle" scope="col">
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {projects.map(project => {
                  return (
                    <tr key={project.id}>
                      <td>
                        <span className="text-grey">{project.name}</span>
                      </td>
                      <td>
                        <div className="text-center">
                          <Link
                            to={`${process.env.PUBLIC_URL}/project/${project.name}`}>
                            <button
                              type="button"
                              className="btn buttEdit btn-circle">
                              <span className="fa fa-eye text-white" />
                            </button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {/*<div className="text-center">
        <Pagination
          onChangePage={handleChangePage}
          currentPage={currentPage}
          totalPages={getNumberOfPages()}
        />
      </div>*/}
    </div>
  );
};

export default Project;
