import React from 'react';
import { useEffect, useState } from 'react';
import './UserFilters.css';
import SelectCryptoCurrencyName from '../common/selects/SelectProjectName';
import SelectUserName from '../common/selects/SelectUserName';

const UserFilters = props => {
  const [state, setState] = useState({
    state: null,
  });

  useEffect(() => {
    props.loadData(getFiltersObject());
  }, [state]);

  const getFiltersObject = () => {
    return {
      where: {
        username: state.username,
      },
    };
  };

  const onFiltersChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setState({ [name]: value.username });
  };

  const onFiltersRefresh = () => {
    setState({
      state: null,
    });
  };

  return (
    <div className="row transactions-filters">
      <div className="col-lg-4 col-sm-6">
        <SelectUserName
          value={state.username}
          name={'username'}
          onChange={onFiltersChange.bind(this)}
        />
      </div>
      <div className="col-lg-8 col-sm-6">
        <div className="text-right">
          <button
            type="button"
            className="btn butt btn-warning "
            onClick={onFiltersRefresh.bind(this)}
            style={{ marginLeft: '2px' }}>
            Reiniciar filtros <i className="fas fa-sync"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserFilters;
