import React from 'react';
import { useEffect, useState } from 'react';
import Select from 'react-select';

const SelectIssueUrgent = props => {
  const [options, setOptions] = useState([
    { value: true, label: 'SI' },
    { value: false, label: 'NO' },
  ]);

  const onChangeWrapper = value => {
    let event = { target: {} };
    event.target.name = props.name ? props.name : 'select-nameless';
    event.target.value = value.value;
    event.target.type = 'react-select';
    props.onChange(event);
  };

  let value = props.value;

  if (value != undefined) {
    value = options.find(option => option.value === value);
  }

  return (
    <Select
      className={props.selectClassName}
      name={props.name}
      onChange={onChangeWrapper.bind(this)}
      value={value || ''}
      required={props.required}
      options={options}
      clearable={false}
      placeholder={props.placeholder}
      menuContainerStyle={{ zIndex: 999 }}
      isDisabled={props.disabled}
    />
  );
};

export default SelectIssueUrgent;
