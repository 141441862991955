import React from 'react';

const Attachments = props => {
  return props.attachments.map(attachment => {
    return (
      <div key={attachment.name} className="row justify-content-center mt-3">
        <div className="col-10">
          <span className="text-white">{attachment.name}</span>
        </div>
        <div className="col-1">
          <button
            onClick={e => props.onRemove(attachment)}
            type="button"
            className="btn buttEdit btn-circle">
            <span className="fas fa-minus text-white" />
          </button>
        </div>
      </div>
    );
  });
};

export default Attachments;
